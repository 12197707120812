import styled from 'styled-components'

export default styled.input`
  font-size: 15px;
  color: rgb(29, 29, 29);
  border-radius: 40px;
  padding: 8px 16px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 2px;
  display: inline-block;
  width: 100%;
  outline: none;
  border: 1.5px solid #333;
  font-family: 'Libre Baskerville';
`
